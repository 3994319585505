import { Component } from '@angular/core';

// App
import { AuthService } from 'shared';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends BaseComponent {
  constructor(
    private _auth: AuthService // Kickstart auth
  ) {
    super();
  }
}
